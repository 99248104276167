import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {LogoutComponent} from './logout/logout.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgottenPasswordComponent} from './forgotten-password/forgotten-password.component';

const routes: Routes = [{
    path: '',
    component: AuthComponent,
    children: [
        {
            path: '',
            component: LoginComponent,
            pathMatch: 'full'
        },
        {
            path: 'login',
            component: LoginComponent,
        },
        {
            path: 'logout',
            component: LogoutComponent,
        },
        {
            path: 'reset-password/:resetToken',
            component: ResetPasswordComponent,
        },
        {
            path: 'forgotten-password',
            component: ForgottenPasswordComponent,
        },
    ],
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}

export const routedComponents = [
    AuthComponent,
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    ForgottenPasswordComponent,
];
