import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Customer} from './customer';
import {CustomerContext} from './customer-context';
import {Warehouse} from './warehouse';
import {WarehouseSelectModalComponent} from '../../@theme/components/warehouse-select-modal/warehouse-select-modal.component';

@Injectable()
export class WarehouseContext {

    public warehouse: Warehouse;
    public warehouseSubject$: BehaviorSubject<Warehouse>;
    public assignedWarehouseSubject$: BehaviorSubject<Warehouse[]>;

    private assignedWarehouses: Warehouse[] = [];
    private customerSubscription: Subscription;
    private customerModalSubscription: Subscription;

    constructor(private customerContext: CustomerContext,
        private modalService: NgbModal) {

        this.loadLocalWarehouse();
        this.warehouseSubject$ = new BehaviorSubject<Warehouse>(this.warehouse);
        this.assignedWarehouseSubject$ = new BehaviorSubject<Warehouse[]>(this.assignedWarehouses);
        this.customerSubscription = this.customerContext.customerSubject$.subscribe(customer => this.customerChanged(customer));
        this.customerModalSubscription = this.customerContext.modalSelected$.subscribe(() => this.showWarehouseModal());
    }

    private customerChanged(customer: Customer): void {
        if (!customer) {
            return;
        }

        this.assignedWarehouses = customer.warehouseAssociations.reduce((warehouses: Warehouse[], wa) => warehouses.concat(wa.warehouse), []);
        this.assignedWarehouseSubject$.next(this.assignedWarehouses);

        // Reset selected warehouse if not assigned
        if (this.warehouse) {
            const isAssigned = this.assignedWarehouses.find(w => w.id === this.warehouse.id) !== undefined;
            if (!isAssigned) {
                this.changeWarehouse(null);
            }
        }

        if (this.assignedWarehouses.length === 1) {
            this.changeWarehouse(this.assignedWarehouses[0]);
        }
    }

    private showWarehouseModal(): void {
        if (this.assignedWarehouses.length > 1) {
            const modal = this.modalService.open(WarehouseSelectModalComponent, {
                size: 'lg',
                container: 'nb-layout',
                backdrop: 'static',
            });
            modal.componentInstance.warehouses = this.assignedWarehouses;
            modal.componentInstance.onSelect = (warehouse: Warehouse) => this.changeWarehouse(warehouse);
        }
    }

    getAssignedWarehouses(): Warehouse[] {
        return this.assignedWarehouses;
    }

    changeWarehouse(warehouse: Warehouse): void {
        this.warehouse = warehouse;
        this.saveWarehouse();
        this.warehouseSubject$.next(this.warehouse);
    }

    private saveWarehouse() {
        localStorage.setItem("warehouse", JSON.stringify(this.warehouse));
    }

    private loadLocalWarehouse() {
        const savedWarehouse = localStorage.getItem("warehouse");
        if (savedWarehouse) {
            this.warehouse = JSON.parse(savedWarehouse);
        }
    }

}
