import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {AuthenticationService} from '../auth.service';
import {EmailValidator} from '../../@theme/validators';

export class Credentials {
    email: string;
    password: string;
}

@Component({
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {

    form: FormGroup;
    submitted: boolean = false;
    error: string = '';

    constructor(fb: FormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private translateService: TranslateService) {
        this.form = fb.group({
            'email': ['', Validators.compose([Validators.required, EmailValidator.validate])],
            'password': ['', Validators.required]
        });
    }

    ngOnInit() {
        this.authService.logout(true);
    }

    public login(values: Credentials): void {
        if (this.form.valid) {

            this.submitted = true;
            this.error = '';

            this.authService.login(values.email, values.password)
                .subscribe(result => {
                    if (result === true) {
                        // login successful
                        if (this.authService.redirectUrl) {
                            this.router.navigateByUrl(this.authService.redirectUrl);
                        } else {
                            this.router.navigate(['/']);
                        }
                    } else {
                        // login failed
                        this.submitted = false;
                        this.error = this.translateService.instant('login.loginFailed');
                    }
                }, err => {
                    this.submitted = false;
                    this.error = err && err.error ? this.translateService.instant('error.' + err.error) : this.translateService.instant('error.serverError');
                });
        }
    }
}
