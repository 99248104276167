import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ba-multi-checkbox[ngModel]',
    templateUrl: './baMultiCheckbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaMultiCheckbox),
            multi: true
        }
    ],
})
export class BaMultiCheckbox implements ControlValueAccessor {

    @Input() baMultiCheckboxClass: string;
    @Input() propertiesMapping: any;

    public state: boolean;

    public constructor() {}

    public getProp(item: any, propName: string): string {
        const prop = this.propertiesMapping[propName];

        if (!prop) {
            return item[propName];
        } else if (typeof prop === 'function') {
            return prop(item);
        }
        return item[prop];
    }
    public onChange(value: any): void {}
    public onTouch(value: any): void {}
    public writeValue(state: any): void {
        this.state = state;
    }

    public registerOnChange(fn: any): void {this.onChange = fn;}
    public registerOnTouched(fn: any): void {this.onTouch = fn;}
}
