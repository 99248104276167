import {FormArray} from '@angular/forms';

export class MinSelectedValidator {

    public static validate(formArray: FormArray, min: number = 1) {
        const totalSelected = formArray.controls
            .map(control => control.value)
            .reduce((prev, next) => next ? prev + next : prev, 0);

        return totalSelected >= min ? null : {required: true};
    }
}
