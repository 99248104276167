import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {CurrentUserContext} from '../../auth/current-user-context';
import {AppSettings} from '../../app.settings';
import {CurrentUser} from '../../auth/current-user';
import {Passwords} from './passwords';

@Injectable()
export class ProfileService {

    constructor(private http: AuthHttpClient,
        private currentUserContext: CurrentUserContext) {}

    getProfile(): Observable<CurrentUser> {
        return this.http.get(AppSettings.PROFILE_URL);
    }

    updateProfile(user: CurrentUser): Observable<any> {
        var data = JSON.stringify(user);

        return this.http.put(AppSettings.PROFILE_URL, data)
            .map(response => {
                this.currentUserContext.refreshCurrentUser();
                return true;
            })
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    changePassword(passwords: Passwords): Observable<any> {
        var data = JSON.stringify(passwords);

        return this.http.put(AppSettings.CHANGE_PASSWORD_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
