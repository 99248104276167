import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Customer} from './customer';
import {User} from './user';
import {UserRoleAssociation} from './user-role';
import {PagedData} from './paged-data';

export class CustomerFilter {
    active?: boolean;
    page?: number;
    pageSize?: number;
    search?: string;
    sortColumn?: string;
    sortDir?: string;
}

@Injectable()
export class CustomerService {

    constructor(private http: AuthHttpClient) {
    }

    getCustomers(params?: CustomerFilter): Observable<PagedData<Customer>> {
        return this.http.get<PagedData<Customer>>(AppSettings.CUSTOMERS_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getCustomerDetail(id: number): Observable<Customer> {
        return this.http.get<Customer>(AppSettings.CUSTOMERS_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createCustomer(customer: Customer): Observable<number> {
        const data = JSON.stringify(customer);

        return this.http.post<HttpResponse<any>>(AppSettings.CUSTOMERS_URL, data, null, {observe: 'response'})
            .map((response: HttpResponse<any>) => {
                const location = response.headers.get('Location');
                const id = location.substring(location.lastIndexOf("/") + 1);
                return parseInt(id);
            })
            .catch((error: HttpErrorResponse) => throwError(error.error)); // Bug in Angular, error parsed as string
    }

    editCustomer(customer: Customer): Observable<any> {
        const data = JSON.stringify(customer);

        return this.http.put(AppSettings.CUSTOMERS_URL + "/" + customer.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteCustomer(customer: Customer): Observable<any> {
        return this.http.delete(AppSettings.CUSTOMERS_URL + "/" + customer.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getUsers(customerID: number): Observable<User[]> {
        return this.http.get<User[]>(AppSettings.CUSTOMERS_URL + "/" + customerID + "/user")
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    addUsers(customerID: number, userRoleAssociations: UserRoleAssociation[]): Observable<boolean> {
        const data = JSON.stringify(userRoleAssociations);

        return this.http.post<HttpResponse<any>>(AppSettings.CUSTOMERS_URL + "/" + customerID + "/user", data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error)); // Bug in Angular, error parsed as string
    }

    exportCustomers(): Observable<Blob> {
        return this.http.getBlob(AppSettings.CUSTOMERS_URL + "/xlsx")
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
