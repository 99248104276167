import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthHttpClient } from '../../auth/http-client';
import { WarehouseContext } from '../../@core/data/warehouse-context';
import { AppSettings } from '../../app.settings';
import { Product } from './product';
import { Warehouse } from './warehouse';
import { Stock } from './stock';
import { PagedData } from './paged-data';
import { ImportAPIResult } from './import-api-result';
import { ImportAPILog } from './import-api-log';
import { CartService } from './cart.service';
import { Filter } from './filter';

export interface ImportAPILogFilter extends Filter {
    warehouse?: number;
}

@Injectable()
export class StockService {

    constructor(private http: AuthHttpClient,
        private warehouseContext: WarehouseContext,
        private cartService: CartService) {
    }

    editStock(stock: Stock): Observable<Stock> {
        var data = JSON.stringify(stock);

        return this.http.put<Stock>(AppSettings.STOCK_URL, data)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    resolveStock(product: Product, warehouse?: Warehouse): void {
        if (!warehouse) {
            warehouse = this.warehouseContext.warehouse;
        }

        let stock: Stock = product.stocks.find(st => st.warehouse.id === warehouse.id);
        if (!stock) {
            stock = {
                warehouse: warehouse,
                totalStock: 0,
                availableStock: 0,
                blockedStock: 0,
                cartBlockedStock: 0,
                expectedStock: 0,
                remainingExpectedStock: 0,
                orderableStock: 0,
            };
        }

        let amountInCart: number = this.cartService.getAmount(product);
        if (stock.availableStock < 0) {
            stock.remainingExpectedStock = stock.availableStock - stock.totalStock + stock.expectedStock + amountInCart;
        }
        stock.availableStock += amountInCart;
        stock.orderableStock += amountInCart;
        product.selectedStock = stock;
    }


    importStockAPI(warehouse: Warehouse): Observable<ImportAPIResult> {
        return this.http.get<ImportAPIResult>(AppSettings.STOCK_URL + "/importAPI?warehouse=" + warehouse.id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    // tslint:disable-next-line: max-line-length
    getImportLog(params: ImportAPILogFilter): Observable<PagedData<ImportAPILog>> {
        return this.http.get<PagedData<ImportAPILog>>(AppSettings.STOCK_URL + "/importAPILog", params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}


