import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ngx-search-input',
    styleUrls: ['./search-input.component.scss'],
    template: `
    <input [placeholder]="'common.searchPlaceholder' | translate"
           #input
           (input)="onInput($event)"/>
    <i class="control-icon ion ion-ios-search" (click)="onSearch()"></i>
  `,
})
export class SearchInputComponent {
    @ViewChild('input') input: ElementRef;

    @Output() search: EventEmitter<string> = new EventEmitter<string>();

    constructor(private translateService: TranslateService) {
    }

    onInput(val: string): void {
        console.log("SEARCH changed");
        this.search.emit(val);
    }

    onSearch(): void {
        console.log("SEARCH clicked");
    }
}
