import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'clock'
})
export class ClockPipe implements PipeTransform {

    transform(seconds: number): string {
        let minutes: number = seconds / 60;
        return seconds + "";
    }

}