import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class LocaleResolver {

    public static EN: string = 'en';
    public static DEFAULT_LANGUAGE: string = LocaleResolver.EN;
    public language: string;

    constructor(private translateService: TranslateService) {
        this.language = localStorage.getItem('lang');
        if (!this.language) {
            this.language = LocaleResolver.DEFAULT_LANGUAGE;
        }
        this.translateService.setDefaultLang(LocaleResolver.DEFAULT_LANGUAGE);
        this.translateService.use(this.language);
    }

    setLanguage(language: string) {
        this.language = language ? language : LocaleResolver.DEFAULT_LANGUAGE;
        this.translateService.use(this.language);
        localStorage.setItem('lang', this.language);
    }
}
