import {Component, OnInit, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {delay, withLatestFrom, takeWhile} from 'rxjs/operators';
import {Subscription} from 'rxjs/Subscription';
import {
    NbMediaBreakpoint,
    NbMediaBreakpointsService,
    NbMenuItem,
    NbMenuService,
    NbSidebarService,
    NbThemeService,
} from '@nebular/theme';

import {StateService} from '../../../@core/data/state.service';
import {WarehouseContext} from '../../../@core/data/warehouse-context';
import {Warehouse} from '../../../@core/data/warehouse';
import {CustomerContext} from '../../../@core/data/customer-context';
import {Customer} from '../../../@core/data/customer';

// TODO: move layouts into the framework
@Component({
    selector: 'ngx-sample-layout',
    styleUrls: ['./sample.layout.scss'],
    template: `
    <nb-layout [center]="layout.id === 'center-column'" windowMode>
      <nb-layout-header fixed id="layout-header">
        <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
      </nb-layout-header>

      <nb-sidebar  id="layout-sidebar"
                   class="menu-sidebar"
                   tag="menu-sidebar"
                   responsive
                   [end]="sidebar.id === 'end'">      
        <customer-select *ngIf="showCustomerSelect" [(ngModel)]="customer" (ngModelChange)="selectCustomer()" [contextDependent]="true"></customer-select>        
        <warehouse-select *ngIf="showWarehouseSelect" [(ngModel)]="warehouse" (ngModelChange)="selectWarehouse()" [contextDependent]="true"></warehouse-select>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-column start class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-column class="small" *ngIf="layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>

      <nb-sidebar class="settings-sidebar"
                   tag="settings-sidebar"
                   state="collapsed"
                   fixed
                   [end]="sidebar.id !== 'end'">
      </nb-sidebar>
    </nb-layout>
  `,
})
export class SampleLayoutComponent implements OnInit, OnDestroy {

    subMenu: NbMenuItem[] = [];
    layout: any = {};
    sidebar: any = {};

    private alive = true;

    currentTheme: string;

    private customerSubscription: Subscription;
    customer: Customer;
    private assignedCustomersSubscription: Subscription;
    showCustomerSelect: boolean = false;

    private warehouseSubscription: Subscription;
    warehouse: Warehouse;
    private allowedWarehousesSubscription: Subscription;
    showWarehouseSelect: boolean = false;

    constructor(protected stateService: StateService,
        protected warehouseContext: WarehouseContext,
        protected customerContext: CustomerContext,
        private cdRef: ChangeDetectorRef,
        protected menuService: NbMenuService,
        protected themeService: NbThemeService,
        protected bpService: NbMediaBreakpointsService,
        protected sidebarService: NbSidebarService) {
        this.stateService.onLayoutState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((layout: string) => this.layout = layout);

        this.stateService.onSidebarState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((sidebar: string) => {
                this.sidebar = sidebar;
            });

        const isBp = this.bpService.getByName('is');
        this.menuService.onItemSelect()
            .pipe(
                takeWhile(() => this.alive),
                withLatestFrom(this.themeService.onMediaQueryChange()),
                delay(20),
            )
            .subscribe(([item, [bpFrom, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {

                if (bpTo.width <= isBp.width) {
                    this.sidebarService.collapse('menu-sidebar');
                }
            });

        this.themeService.getJsTheme()
            .pipe(takeWhile(() => this.alive))
            .subscribe(theme => {
                this.currentTheme = theme.name;
            });
    }

    ngOnInit() {
        this.allowedWarehousesSubscription = this.warehouseContext.assignedWarehouseSubject$.subscribe(warehouses => this.showWarehouseSelect = warehouses.length > 1);
        this.warehouseSubscription = this.warehouseContext.warehouseSubject$.subscribe(warehouse => this.warehouse = warehouse);
        this.assignedCustomersSubscription = this.customerContext.assignedCustomerSubject$.subscribe(customers => this.showCustomerSelect = customers.length > 1);
        this.customerSubscription = this.customerContext.customerSubject$.subscribe(customer => {
            this.customer = customer;
            this.cdRef.detectChanges();
        });
    }

    ngOnDestroy() {
        if (this.warehouseSubscription) {
            this.warehouseSubscription.unsubscribe();
        }
        if (this.allowedWarehousesSubscription) {
            this.allowedWarehousesSubscription.unsubscribe();
        }
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
        if (this.assignedCustomersSubscription) {
            this.assignedCustomersSubscription.unsubscribe();
        }
        this.alive = false;
    }

    selectWarehouse() {
        this.warehouseContext.changeWarehouse(this.warehouse);
    }

    selectCustomer() {
        this.customerContext.changeCustomer(this.customer);
    }
}
