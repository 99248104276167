import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';

@Injectable()
export class VersionCheckService {

    versionChanged: Subject<boolean> = new Subject();

    // this will be replaced by actual hash post-build.js
    private currentHash: string = '{{POST_BUILD_ENTERS_HASH_HERE}}';

    constructor(private http: HttpClient) {}

    public initVersionCheck(url: string, frequency: number = 1000 * 60) {
        if (url) {
            setInterval(() => {
                this.checkVersion(url);
            }, frequency);
        }
    }

    private checkVersion(url: string) {
        this.http.get(url + '?t=' + new Date().getTime())
            .first()
            .subscribe(
            (response: any) => {
                if (response && response.hash) {
                    const hash = response.hash;
                    const hashChanged = this.hasHashChanged(this.currentHash, hash);
                    if (hashChanged) {
                        this.versionChanged.next(true);
                    }
                    this.currentHash = hash;
                }
            },
            (err) => {
                console.error(err, 'Could not get version');
            }
            );

    }

    private hasHashChanged(currentHash: string, newHash: string) {
        return currentHash && currentHash !== newHash;
    }
}