import {Injectable} from '@angular/core';
import {Subject, BehaviorSubject, Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {CurrentUser} from '../../auth/current-user';
import {CurrentUserContext} from '../../auth/current-user-context';
import {Customer} from './customer';
import {CustomerService} from './customer.service';
import {CustomerSelectModalComponent} from '../../@theme/components/customer-select-modal/customer-select-modal.component';

@Injectable()
export class CustomerContext {

    public customer: Customer;
    public customerSubject$: BehaviorSubject<Customer>;
    public assignedCustomerSubject$: BehaviorSubject<Customer[]>;

    private modalSelectedSource = new Subject<Customer>();
    public modalSelected$ = this.modalSelectedSource.asObservable();

    private assignedCustomers: Customer[] = [];
    private userSubscription: Subscription;

    constructor(private customerService: CustomerService,
        private currentUserContext: CurrentUserContext,
        private modalService: NgbModal) {

        this.loadLocalCustomer();
        this.customerSubject$ = new BehaviorSubject<Customer>(this.customer);
        this.assignedCustomerSubject$ = new BehaviorSubject<Customer[]>(this.assignedCustomers);
        this.userSubscription = this.currentUserContext.currentUserSubject$.subscribe(this.currentUserChanged.bind(this));
    }

    private currentUserChanged(currentUser: CurrentUser): void {
        if (!currentUser) {
            return;
        }

        this.assignedCustomers = currentUser.userRoleAssociations
            .filter(ura => ura.userRole.customerDependent)
            .reduce((acc: Customer[], ura) => acc.concat(ura.customers), []);
        this.assignedCustomerSubject$.next(this.assignedCustomers);

        // Reset selected customer if not assigned
        if (this.customer) {
            const isAssigned = this.assignedCustomers.find(customer => customer.id === this.customer.id) !== undefined;
            if (!isAssigned) {
                this.changeCustomer(null);
            }
        }

        // Select a customer if empty
        if (!this.customer && this.assignedCustomers.length > 0) {
            if (this.assignedCustomers.length > 1) {
                setTimeout(() => {
                    const modal = this.modalService.open(CustomerSelectModalComponent, {
                        size: 'lg',
                        container: 'nb-layout',
                        backdrop: 'static',
                    });
                    modal.componentInstance.customers = this.assignedCustomers;
                    modal.componentInstance.onSelect = (customer: Customer) => this.changeCustomer(customer, true);
                }, 500);
            } else {
                this.changeCustomer(this.assignedCustomers[0]);
            }
        }
    }

    getAssignedCustomers(): Customer[] {
        return this.assignedCustomers;
    }

    changeCustomer(customer: Customer, fromModal?: boolean): void {
        if (!customer) {
            this.customer = null;
            this.saveCustomer();
            this.customerSubject$.next(this.customer);
            return;
        }

        this.customerService.getCustomerDetail(customer.id).subscribe(
            customer => {
                this.customer = customer;
                this.saveCustomer();
                this.customerSubject$.next(this.customer);

                if (fromModal) {
                    this.modalSelectedSource.next(customer);
                }
            },
            err => {
                // TODO 
                this.customer = null;
                this.saveCustomer();
                this.customerSubject$.next(this.customer);
            }
        );
    }

    private saveCustomer() {
        localStorage.setItem("customer", JSON.stringify(this.customer));
    }

    private loadLocalCustomer() {
        const savedCustomer = localStorage.getItem("customer");
        if (savedCustomer) {
            this.customer = JSON.parse(savedCustomer);
        }
    }

}
