import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Category} from './category';

@Injectable()
export class CategoryService {

    constructor(private http: AuthHttpClient) {
    }

    getCategories(active?: boolean): Observable<Category[]> {
        let params = <any> {};
        if (active !== undefined) {
            params.active = active;
        }
        return this.http.get<Category[]>(AppSettings.PRODUCT_CATEGORIES_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getCategoryDetail(id: number): Observable<Category> {
        return this.http.get<Category>(AppSettings.PRODUCT_CATEGORIES_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createCategory(category: Category): Observable<any> {
        var data = JSON.stringify(category);

        return this.http.post(AppSettings.PRODUCT_CATEGORIES_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editCategory(category: Category): Observable<any> {
        var data = JSON.stringify(category);

        return this.http.put(AppSettings.PRODUCT_CATEGORIES_URL + "/" + category.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteCategory(category: Category): Observable<any> {
        return this.http.delete(AppSettings.PRODUCT_CATEGORIES_URL + "/" + category.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}

