import {Component, OnInit} from '@angular/core';

import {AuthenticationService} from '../auth.service';

@Component({
    template: `<router-outlet></router-outlet>`
})
export class LogoutComponent implements OnInit {

    constructor(private authService: AuthenticationService) {
    }

    ngOnInit() {
        this.authService.logout();
    }

}
