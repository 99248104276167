import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router, ActivatedRoute, Params} from '@angular/router';

import {EqualPasswordsValidator} from '../../@theme/validators/equalPasswords.validator';
import {AuthenticationService} from '../auth.service';

@Component({
    templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

    readonly PASSWORD_MIN_LENGTH: number = 5;
    form: FormGroup;
    passwords: FormGroup;
    submitted: boolean = false;
    error: boolean = false;
    resetToken: string;

    constructor(fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthenticationService) {

        this.passwords = fb.group({
            'newPassword': ['', Validators.compose([Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)])],
            'repeatPassword': ['', Validators.compose([Validators.required, Validators.minLength(this.PASSWORD_MIN_LENGTH)])]
        }, {validator: EqualPasswordsValidator.validate('newPassword', 'repeatPassword')})

        this.form = fb.group({
            'passwords': this.passwords
        });
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.resetToken = params['resetToken'];
        });
    }

    onSubmit(values: any) {
        this.submitted = true;
        this.error = false;

        this.authService.resetPassword(this.resetToken, values.passwords.newPassword)
            .subscribe(response => {
                this.authService.saveLoggedUser(response);
                this.router.navigateByUrl("/");
                this.submitted = false;
                this.error = false;
            }, err => {
                this.submitted = false;
                this.error = true;
            });
    }
}
