import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Range} from './range';

export interface RangeFilter {
    active?: boolean;
    customerRelevant?: boolean;
    customer?: number;
}

@Injectable()
export class RangeService {

    constructor(private http: AuthHttpClient) {
    }

    getRanges(filter?: RangeFilter): Observable<Range[]> {
        return this.http.get<Range[]>(AppSettings.PRODUCT_RANGES_URL, filter)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getRangeDetail(id: number): Observable<Range> {
        return this.http.get<Range>(AppSettings.PRODUCT_RANGES_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createRange(range: Range): Observable<any> {
        var data = JSON.stringify(range);

        return this.http.post(AppSettings.PRODUCT_RANGES_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editRange(range: Range): Observable<any> {
        var data = JSON.stringify(range);

        return this.http.put(AppSettings.PRODUCT_RANGES_URL + "/" + range.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteRange(range: Range): Observable<any> {
        return this.http.delete(AppSettings.PRODUCT_RANGES_URL + "/" + range.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    downloadFiles(range: Range): Observable<any> {
        return this.http.getBlob(AppSettings.PRODUCT_RANGES_URL + "/" + range.id + "/files", "application/zip")
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
