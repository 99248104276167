import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Color} from './color';

@Injectable()
export class ColorService {

    constructor(private http: AuthHttpClient) {
    }

    getColors(active?: boolean): Observable<Color[]> {
        let params = <any> {};
        if (active !== undefined) {
            params.active = active;
        }
        return this.http.get<Color[]>(AppSettings.PRODUCT_COLORS_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getColorDetail(id: number): Observable<Color> {
        return this.http.get<Color>(AppSettings.PRODUCT_COLORS_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createColor(color: Color): Observable<any> {
        var data = JSON.stringify(color);

        return this.http.post(AppSettings.PRODUCT_COLORS_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editColor(color: Color): Observable<any> {
        var data = JSON.stringify(color);

        return this.http.put(AppSettings.PRODUCT_COLORS_URL + "/" + color.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteColor(color: Color): Observable<any> {
        return this.http.delete(AppSettings.PRODUCT_COLORS_URL + "/" + color.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
