import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

import {CartMessage} from '../../../@core/data/order';

@Component({
    selector: 'cart-messages-modal',
    styleUrls: ['./cart-messages-modal.component.scss'],
    templateUrl: './cart-messages-modal.component.html',
})
export class CartMessagesModalComponent {

    @Input()
    messages: CartMessage[] = [];

    constructor(private activeModal: NgbActiveModal,
        private router: Router,
        private translateService: TranslateService) {
    }

    closeModal() {
        this.activeModal.close();
    }
}