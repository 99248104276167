import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';

@Injectable()
export class EmailService {

    constructor(private http: AuthHttpClient) {
    }

    getEmailAddresses(): Observable<string[]> {
        return this.http.get<string[]>(AppSettings.EMAIL_URL)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
