export class AppSettings {
    public static VERSION = "1.13";

    public static API_ENDPOINT = '/api';

    public static LOGIN_URL = AppSettings.API_ENDPOINT + '/auth/login';
    public static REFRESH_TOKEN_URL = AppSettings.API_ENDPOINT + '/auth/refreshToken';
    public static FORGOTTEN_PASSWORD_URI = AppSettings.API_ENDPOINT + '/auth/forgottenPassword';
    public static RESET_PASSWORD_URI = AppSettings.API_ENDPOINT + '/auth/resetPassword';
    public static USERS_URL = AppSettings.API_ENDPOINT + '/user';
    public static PROFILE_URL = AppSettings.USERS_URL + '/current';
    public static CHANGE_PASSWORD_URL = AppSettings.PROFILE_URL + '/password';
    public static ROLES_URL = AppSettings.API_ENDPOINT + '/userrole';
    public static AUTHORITIES_URL = AppSettings.API_ENDPOINT + '/authority';
    public static AUTHORITY_AREAS_URL = AppSettings.API_ENDPOINT + '/authorityArea';
    public static COUNTRIES_URL = AppSettings.API_ENDPOINT + '/country';
    public static CUSTOMERS_URL = AppSettings.API_ENDPOINT + '/customer';
    public static PRODUCT_RANGES_URL = AppSettings.API_ENDPOINT + '/catalogRange';
    public static PRODUCT_CATEGORIES_URL = AppSettings.API_ENDPOINT + '/catalogCategory';
    public static PRODUCT_COLORS_URL = AppSettings.API_ENDPOINT + '/catalogColor';
    public static PRODUCTS_URL = AppSettings.API_ENDPOINT + '/catalogProduct';
    public static WAREHOUSES_URL = AppSettings.API_ENDPOINT + '/warehouse';
    public static FILES_URL = AppSettings.API_ENDPOINT + '/file';
    public static FILE_CATEGORIES_URL = AppSettings.API_ENDPOINT + '/fileCategory';
    public static CART_URL = AppSettings.API_ENDPOINT + '/cart';
    public static ORDERS_URL = AppSettings.API_ENDPOINT + '/order';
    public static STOCK_URL = AppSettings.API_ENDPOINT + '/stock';
    public static SETTINGS_URL = AppSettings.API_ENDPOINT + '/settings';
    public static EMAIL_URL = AppSettings.API_ENDPOINT + '/email';
    public static PING_URL = AppSettings.API_ENDPOINT + '/ping';
    public static APP_LOG_URL = AppSettings.API_ENDPOINT + '/log';
}
