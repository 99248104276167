<nb-layout>
    <nb-layout-column>
        <nb-card>
            <nb-card-body>
                <div class="flex-centered col-xl-4 col-lg-6 col-md-8 col-sm-12">
                    <div class="logo">
                        <img src="/assets/images/logo-brown.png" alt="Global Home Group" class="img-fluid" />
                    </div>

                    <router-outlet></router-outlet>

                    <div class="row links">
                        <div class="col-6">
                            <div class="section">
                                <p><strong>{{ 'contact.wholesale' | translate }}</strong></p>
                                <p class="contact"><i class="far fa-envelope"></i> <a href="mailto:info@globalhomegroup.com" target="_blank">info@globalhomegroup.com</a></p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="section">
                                <p><strong>{{ 'contact.container' | translate }}</strong></p>
                                <p class="contact"><i class="far fa-envelope"></i> <a href="mailto:dung@globalhomegroup.com" target="_blank">dung@globalhomegroup.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </nb-layout-column>
</nb-layout>