<div class="modal-header">
    <h3>{{ 'cart.messages' | translate }}</h3>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="message" *ngFor="let message of messages">
         <strong *ngIf="message.product">{{ message.product.title }}:&nbsp;</strong>
         <span>{{ ('error.' + message.messageCode) | translate:{additional: message.additionalInfo} }}</span>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-md btn-primary" (click)="closeModal()"><i class="fas fa-check"></i> {{ 'common.ok' | translate }}</button>
</div>