import {Component, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

import {Warehouse} from '../../../@core/data/warehouse'

@Component({
    selector: 'warehouse-select-modal',
    styleUrls: ['./warehouse-select-modal.component.scss'],
    templateUrl: './warehouse-select-modal.component.html',
})
export class WarehouseSelectModalComponent {

    @Input()
    warehouses: Warehouse[] = [];

    @Input()
    onSelect: Function;

    constructor(private activeModal: NgbActiveModal,
        private translateService: TranslateService) {
    }

    select(warehouse: Warehouse) {
        if (this.onSelect) {
            this.onSelect(warehouse);
        }
        this.activeModal.close();
    }
}