import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'reload-modal',
    template: `
    <div class="modal-header">
        <h3>{{ 'reloadModal.title' | translate }}</h3>
        <button class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ 'reloadModal.text' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-md btn-primary" (click)="reload()"><i class="fa fa-refresh" aria-hidden="true"></i> {{ 'reloadModal.reloadBtn' | translate }}</button>
        <button class="btn btn-md btn-secondary" (click)="closeModal()">{{ 'common.back' | translate }}</button>
    </div>
`
})
export class ReloadModalComponent {

    constructor(private activeModal: NgbActiveModal,
        private translateService: TranslateService) {
    }

    reload() {
        location.reload();
    }

    closeModal() {
        this.activeModal.close();
    }
}