import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpEvent} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {AuthenticationService} from './auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    private refreshTokenInProgress = false;

    private refreshTokenSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(public authService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request)
            .catch((error: HttpErrorResponse) => {
                // We don't want to refresh token for some requests like login or refresh token itself
                // So we verify url and we throw an error if it's the case
                if (request.url.includes("refreshToken") || request.url.includes("login")) {

                    // We do another check to see if refresh token failed
                    // In this case we want to logout user and to redirect it to login page
                    if (request.url.includes("refreshToken")) {
                        this.authService.logout();
                    }

                    return throwError(error);
                }


                // If error status is different than 401 we want to skip refresh token
                // So we check that and throw the error if it's the case
                if (error.status !== 401) {
                    return throwError(error);
                }

                if (this.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject$
                        .filter(result => result !== null)
                        .take(1)
                        .switchMap(() => next.handle(this.addAuthenticationToken(request)));
                } else {
                    this.refreshTokenInProgress = true;

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject$.next(null);

                    // Call auth.refreshToken(this is an Observable that will be returned)
                    return this.authService
                        .refreshToken()
                        .switchMap((token: any) => {
                            this.refreshTokenSubject$.next(token);
                            return next.handle(this.addAuthenticationToken(request));
                        })
                        .catch((err: any) => {
                            this.authService.logout();
                            return throwError(error);
                        })
                        .finally(() => this.refreshTokenInProgress = false);
                }

            });
    }

    addAuthenticationToken(request: HttpRequest<any>) {
        // Get access token from Local Storage
        const token = localStorage.getItem("accessToken");

        // If access token is null this means that user is not logged in and we return the original request
        if (!token) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: "Bearer " + token
            }
        });
    }
}