import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthorityService } from './authority.service';
import { CountryService } from './country.service';
import { CustomerService } from './customer.service';
import { FileService } from './file.service';
import { LayoutService } from './layout.service';
import { ProfileService } from './profile.service';
import { RangeService } from './range.service';
import { CartService } from './cart.service';
import { CategoryService } from './category.service';
import { ColorService } from './color.service';
import { WarehouseService } from './warehouse.service';
import { ProductService } from './product.service';
import { StateService } from './state.service';
import { UserService } from './user.service';
import { UserRoleService } from './user-role.service';
import { OrderService } from './order.service';
import { FileCategoryService } from './file-category.service';
import { StockService } from './stock.service';
import { SettingsService } from './settings.service';
import { EmailService } from './email.service';
import { VersionCheckService } from './version-check.service';
import { WarehouseContext } from './warehouse-context';
import { CustomerContext } from './customer-context';
import { PingService } from './ping.service';
import { AppLogService } from './app-log.service';

const SERVICES = [
    AuthorityService,
    CountryService,
    CustomerService,
    FileService,
    LayoutService,
    ProfileService,
    RangeService,
    CartService,
    CategoryService,
    ColorService,
    WarehouseService,
    ProductService,
    StateService,
    UserService,
    UserRoleService,
    OrderService,
    FileCategoryService,
    StockService,
    SettingsService,
    EmailService,
    VersionCheckService,
    WarehouseContext,
    CustomerContext,
    PingService,
    AppLogService,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        ...SERVICES,
    ],
})
export class DataModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders>{
            ngModule: DataModule,
            providers: [
                ...SERVICES,
            ],
        };
    }
}
