import {Injectable} from '@angular/core';
import {Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {AuthenticationService} from './auth.service';
import {Authorizator} from './authorizator';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private router: Router, private authService: AuthenticationService, private authorizator: Authorizator) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.isLoggedIn()) {
            return true;
        }

        // keep the attempted URL for redirecting
        this.authService.redirectUrl = state.url;

        // not logged in so redirect to login page
        this.router.navigate(['/auth/login'], { skipLocationChange: true });
        return false;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // authorization according to the route settings
        let authorityParam = route.data['authority'];
        if (authorityParam) {
            let authorized = false;
            if (Array.isArray(authorityParam)) {
                authorityParam.forEach((authority: string) => {
                    if (this.authorizator.hasAuthority(authority)) {
                        authorized = true;
                        return;
                    }
                });
            } else if (this.authorizator.hasAuthority(authorityParam)) {
                authorized = true;
            }

            if (!authorized) {
                this.router.navigate(['/error/403'], {skipLocationChange: true});
                return false;
            }
        }
        return this.canActivate(route, state);
    }
}
