import {Component, OnInit, HostListener} from '@angular/core';

import {LocaleResolver} from '../@core/utils/locale.resolver';

@Component({
    selector: 'ngx-auth',
    styleUrls: ['./auth.component.scss'],
    templateUrl: 'auth.component.html',
})
export class AuthComponent implements OnInit {

    scrolled: boolean = false;
    currentLang: string = '';
    showMobileMenu: boolean = false;

    constructor(private localeResolver: LocaleResolver) {
    }

    ngOnInit() {
        this.currentLang = this.localeResolver.language;
    }

    changeLanguage(lang: string) {
        this.localeResolver.setLanguage(lang);
        this.currentLang = lang;
        return false;
    }

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.scrolled = window.pageYOffset > 0;
    }

    toggleMobileMenu(): void {
        this.showMobileMenu = !this.showMobileMenu;
    }
}
