import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Router} from '@angular/router';

import {AppSettings} from '../app.settings';
import {CurrentUser} from './current-user';

@Injectable()
export class CurrentUserContext {

    public currentUser: CurrentUser;
    public currentUserSubject$: BehaviorSubject<CurrentUser> = new BehaviorSubject<CurrentUser>(null);

    constructor(private http: HttpClient,
        private router: Router) {

        this.loadLocalCurrentUser();

        if (this.currentUser) {
            this.refreshCurrentUser();
        }
    }

    saveCurrentUser(currentUser: CurrentUser): void {
        this.currentUser = currentUser;

        if (currentUser.userRoleAssociations) {
            this.currentUser.userRolesNames = currentUser.userRoleAssociations.map((roleAssociation) => roleAssociation.userRole.name);
        } else {
            this.currentUser.userRolesNames = [];
        }

        this.currentUserSubject$.next(this.currentUser);
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    }

    isLoggedIn() {
        return this.currentUser !== null;
    }

    refreshCurrentUser(): void {
        const headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + localStorage.getItem("accessToken"))
            .append('Content-Type', 'application/json');

        setTimeout(() => {
            this.http.get<CurrentUser>(AppSettings.PROFILE_URL, {headers: headers})
            .subscribe(user => {
                this.saveCurrentUser(user);
            }, err => {
                // Unauthorized (local storage is out of date) => redirect to login
                this.router.navigate(['/auth/login']);
            });
        });
    }

    private loadLocalCurrentUser(): void {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
}
