import {Injectable} from '@angular/core';

import {CurrentUserContext} from './current-user-context';

@Injectable()
export class Authorizator {

    constructor(private currentUserContext: CurrentUserContext) {}

    hasAuthority(authority: string) {
        return this.currentUserContext.currentUser.authorities.indexOf(authority) !== -1;
    }

}
