import {Component, OnInit, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';
import {TranslateService} from '@ngx-translate/core';

import {Customer} from '../../../@core/data/customer';
import {CustomerService} from '../../../@core/data/customer.service';
import {CustomerContext} from '../../../@core/data/customer-context';

@Component({
    selector: 'customer-select',
    template: `
        <ng-select 
           [labelForId]="id"
           [items]="customers"
           bindLabel="name"
           bindValue="id"
           [(ngModel)]="selectedIDs"
           [multiple]="multiple"
           [placeholder]="placeholder"
           [clearable]="clearable"
           [disabled]="disabled"
           (change)="onInternalChange($event)">
        </ng-select>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomerSelectComponent),
            multi: true
        }
    ],
})
export class CustomerSelectComponent implements OnInit, ControlValueAccessor {

    @Input() id: string;
    @Input() all: boolean = false;
    @Input() multiple: boolean = false;
    @Input() placeholder: string;
    @Input() setDefault: boolean = false;
    @Input() clearable: boolean = false;
    @Input() disabled: boolean = false;
    @Input() contextDependent: boolean = false;

    customers: Customer[] = [];
    selectedIDs: number | number[];
    initValue: Customer | Customer[];
    private customersSubscription: Subscription;

    constructor(private customerService: CustomerService,
        private translateService: TranslateService,
        private customerContext: CustomerContext, ) {
    }

    ngOnInit() {
        if (this.contextDependent) {
            this.customersSubscription = this.customerContext.assignedCustomerSubject$.subscribe(customers => this.init(customers));
        } else {
            this.customerService.getCustomers({active: true})
                .subscribe(customers => this.init(customers.data));
        }

        if (this.placeholder === undefined) {
            this.placeholder = this.translateService.instant('common.generalPlaceholder');
        }
    }

    ngOnDestroy() {
        if (this.customersSubscription) {
            this.customersSubscription.unsubscribe();
        }
    }

    private init(customers: Customer[]): void {
        this.customers = customers;
        if (this.all) {
            this.customers.unshift({
                id: 0,
                name: this.translateService.instant('customers.allSelect'),
            });
        }
        if (this.setDefault) {
            this.setDefaultValue();
        }
    }

    onInternalChange(value: any) {
        if (value && value.id === 0) {
            this.onChange(null);
        } else {
            this.onChange(value);
        }
    }

    private setDefaultValue() {
        if (this.initValue) {
            if (Array.isArray(this.initValue)) {
                this.selectedIDs = this.initValue.map(customer => customer.id);
            } else {
                this.selectedIDs = this.initValue.id;
            }
        }

        if (!this.selectedIDs && this.setDefault && this.customers.length > 0) {
            this.selectedIDs = this.customers[0].id;
            this.onInternalChange(this.customers[0]);
        }
    }

    public onChange(value: any): void {}
    public onTouch(value: any): void {}
    public writeValue(value: Customer | Customer[]): void {
        this.initValue = value;
        this.setDefaultValue();
    }
    public registerOnChange(fn: any): void {this.onChange = fn;}
    public registerOnTouched(fn: any): void {this.onTouch = fn;}
}
