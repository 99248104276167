import {Component, OnInit, OnDestroy, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs/Subscription';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';

import {WarehouseService} from '../../../@core/data/warehouse.service'
import {Warehouse} from '../../../@core/data/warehouse'
import {WarehouseContext} from '../../../@core/data/warehouse-context'

@Component({
    selector: 'warehouse-select',
    template: `        
        <ng-select 
           [labelForId]="id"
           [items]="warehouses"
           bindLabel="title"
           bindValue="id"
           [(ngModel)]="selectedIDs"
           [multiple]="multiple"
           [placeholder]="placeholder"
           [clearable]="clearable"
           [disabled]="disabled"
           (change)="onInternalChange($event)">
        </ng-select>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => WarehouseSelectComponent),
            multi: true
        }
    ],
})
export class WarehouseSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() id: any;
    @Input() all: boolean = false;
    @Input() multiple: boolean = false;
    @Input() setDefault: boolean = false;
    @Input() placeholder: string;
    @Input() clearable: boolean = false;
    @Input() disabled: boolean = false;
    @Input() onlyCustomer: boolean = false;
    @Input() contextDependent: boolean = false;

    warehouses: Warehouse[] = [];
    selectedIDs: number | number[];
    initValue: Warehouse | Warehouse[];
    private warehousesSubscription: Subscription;

    public constructor(private warehouseService: WarehouseService,
        private warehouseContext: WarehouseContext,
        private translateService: TranslateService,
        private toasterService: ToasterService) {
    }

    ngOnInit() {
        this.loadWarehouses();
    }

    ngOnDestroy() {
        if (this.warehousesSubscription) {
            this.warehousesSubscription.unsubscribe();
        }
    }

    private loadWarehouses() {
        if (this.contextDependent) {
            this.warehousesSubscription = this.warehouseContext.assignedWarehouseSubject$.subscribe(warehouses => this.init(warehouses));
        } else {
            this.warehouseService.getWarehouses(true, this.onlyCustomer)
                .subscribe(
                    warehouses => this.init(warehouses),
                    err => {
                        this.toasterService.popAsync({
                            type: 'error',
                            body: err && err.error ? err.error : this.translateService.instant('warehouses.loadError')
                        });
                    });
        }

        if (this.placeholder === undefined) {
            this.placeholder = this.translateService.instant('common.generalPlaceholder');
        }
    }

    private init(warehouses: Warehouse[]): void {
        this.warehouses = warehouses;
        this.warehouses.sort((w1, w2) => w1.title >= w2.title ? 1 : -1);
        if (this.all) {
            this.warehouses.unshift({
                id: 0,
                title: this.translateService.instant('catalog.warehouses.allSelect'),
            });
        }
        this.setDefaultValue();
    }

    onInternalChange(value: any) {
        if (value && value.id === 0) {
            this.onChange(null);
        } else {
            this.onChange(value);
        }
    }

    private setDefaultValue() {
        if (this.initValue) {
            if (Array.isArray(this.initValue)) {
                this.selectedIDs = this.initValue.map(range => +range.id);
            } else {
                this.selectedIDs = +this.initValue.id;
            }
        }

        if (!this.selectedIDs && this.setDefault && this.warehouses.length > 0) {
            this.selectedIDs = this.warehouses[0].id;
            this.onInternalChange(this.warehouses[0]);
        }
    }

    public onChange(value: any): void {}
    public onTouch(value: any): void {}
    public writeValue(value: Warehouse | Warehouse[]): void {
        this.initValue = value;
        this.setDefaultValue();
    }
    public registerOnChange(fn: any): void {this.onChange = fn;}
    public registerOnTouched(fn: any): void {this.onTouch = fn;}
}
