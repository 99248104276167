import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'ngx-modal',
    templateUrl: './modal.component.html'
})
export class ModalComponent {

    @Input()
    header: string;

    @Input()
    content: string;

    @Input()
    showButton: boolean = true;

    @Input()
    buttonText: string;

    @Input()
    buttonType: string = 'primary';

    @Input()
    onButtonClicked: Function;

    constructor(private activeModal: NgbActiveModal,
        private translateService: TranslateService) {
        if (!this.buttonText) {
            this.header = this.translateService.instant('common.delete');
            this.buttonText = this.translateService.instant('common.delete');
        }
    }

    closeModal() {
        this.activeModal.close();
    }

    buttonClicked(): void {
        this.onButtonClicked();
        this.closeModal();
    }
}
