import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {UserRole} from './user-role';

@Injectable()
export class UserRoleService {

    constructor(private http: AuthHttpClient) {
    }

    getUserRoles(): Observable<UserRole[]> {
        return this.http.get<UserRole[]>(AppSettings.ROLES_URL)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getUserRoleDetail(id: number): Observable<UserRole> {
        return this.http.get<UserRole>(AppSettings.ROLES_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createUserRole(role: UserRole): Observable<any> {
        var data = JSON.stringify(role);

        return this.http.post(AppSettings.ROLES_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editUserRole(role: UserRole): Observable<any> {
        var data = JSON.stringify(role);

        return this.http.put(AppSettings.ROLES_URL + "/" + role.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    invalidateUserRole(role: UserRole): Observable<any> {
        return this.http.delete(AppSettings.ROLES_URL + "/" + role.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
