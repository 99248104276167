import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { LocaleResolver } from './locale.resolver';

@Injectable()
export class DateUtils {

    static readonly SYSTEM_DATE_FORMAT = "YYYY-MM-DD";
    static readonly DATE_FORMAT = "DD/MM/YYYY";
    static readonly DATETIME_FORMAT = "DD/MM/YYYY HH:mm";
    static readonly DATETIME_PRECISE_FORMAT = "DD/MM/YYYY HH:mm:ss";

    constructor(private localeResolver: LocaleResolver) { }

    resolveDateFormat(): string {
        return DateUtils.DATE_FORMAT;
    }

    resolveDatetimeFormat(): string {
        return DateUtils.DATETIME_FORMAT;
    }

    format(date: Date): string {
        if (date) {
            let yearString = date.getFullYear();

            let monthString: string = date.getMonth() + 1 + "";
            if (monthString.length < 2) {
                monthString = "0" + monthString;
            }

            let dayString: string = date.getDate() + "";
            if (dayString.length < 2) {
                dayString = "0" + dayString;
            }

            return yearString + "-" + monthString + "-" + dayString;
        }
        return "";
    }

    transform(value: string, format: string = ""): string {
        // Try and parse the passed value.
        var momentDate = moment(value);

        // If moment didn't understand the value, return it unformatted.
        if (!momentDate.isValid()) return value;

        // Otherwise, return the date formatted as requested.
        return momentDate.format(format);
    }
}
