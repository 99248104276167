import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, throwError, interval } from 'rxjs';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';

import { CartMessagesModalComponent } from '../../@theme/components/cart-messages-modal/cart-messages-modal.component';
import { ModalComponent } from '../../@theme/components/modal/modal.component';
import { CartTimeoutModalComponent } from '../../@theme/components/cart-timeout-modal/cart-timeout-modal.component';
import { AuthHttpClient } from '../../auth/http-client';
import { CurrentUserContext } from '../../auth/current-user-context';
import { WarehouseContext } from '../../@core/data/warehouse-context';
import { CustomerContext } from '../../@core/data/customer-context';
import { Authorizator } from '../../auth/authorizator';
import { AppSettings } from '../../app.settings';
import { Product } from './product';
import { Warehouse } from './warehouse';
import { Customer } from './customer';
import { Cart, OrderItem } from './order';
import { DateUtils } from '../utils/date.utils';

@Injectable()
export class CartService implements OnDestroy {

    cart: Cart;
    cartSubject$: BehaviorSubject<Cart>;
    containerCapacity: number;
    totalContainerCapacity: number;
    almostFullContainer: boolean = false;
    containersCount: number;
    private userSubscription: Subscription;
    private warehouseSubscription: Subscription;
    private customerSubscription: Subscription;
    private timerSubscription: Subscription;
    private timeoutModalShown: boolean;
    private warningModal: NgbModalRef;

    constructor(private http: AuthHttpClient,
        private currentUserContext: CurrentUserContext,
        private modalService: NgbModal,
        private authorizator: Authorizator,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        private warehouseContext: WarehouseContext,
        private customerContext: CustomerContext,
        private dateUtils: DateUtils) {

        this.cartSubject$ = new BehaviorSubject<Cart>(this.cart);

        this.userSubscription = this.currentUserContext.currentUserSubject$.subscribe(currentUser => {
            if (this.authorizator.hasAuthority("CREATE_ORDER")) {
                this.loadCart().subscribe();
            }
        });

        this.warehouseSubscription = this.warehouseContext.warehouseSubject$.subscribe(warehouse => {
            if (warehouse && (!this.cart || !this.cart.warehouse || warehouse.id !== this.cart.warehouse.id)) {
                this.warehouseChanged(warehouse).subscribe();
            }
        });

        this.customerSubscription = this.customerContext.customerSubject$.subscribe(customer => {
            if (customer && (!this.cart || !this.cart.customer || customer.id !== this.cart.customer.id)) {
                this.customerChanged(customer).subscribe();
            }
        });

        this.loadContainerCapacity();
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
        this.warehouseSubscription.unsubscribe();
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    getCartItemsCount(): number {
        if (!this.cart || !this.cart.items) {
            return 0;
        }

        let count = 0;
        this.cart.items.forEach(item => {
            if (item.product) {
                count += item.amount;
            }
        });
        return count;
    }

    getCartTotalVolume(): number {
        if (!this.cart || !this.cart.items) {
            return 0
        }

        let volume = 0;
        this.cart.items.forEach(item => {
            if (item.product) {
                volume += item.product.volume * item.amount;
            }
        });
        return volume;
    }

    /**
     * In seconds.
     */
    private getRemainingValidityTime(): number {
        if (!this.cart || !this.cart.items || !this.cart.warehouse) {
            return -1;
        }

        const cartLimit = this.cart.warehouse.cartValidityLimit; // minutes
        const elapsedTime = this.getElapsedValidityTime(); // seconds

        return Math.floor(cartLimit * 60 - elapsedTime);
    }

    /**
     * In seconds.
     */
    private getElapsedValidityTime(): number {
        if (!this.cart || !this.cart.items) {
            return -1;
        }

        const now = Date.now(); // UTC
        return (now - this.cart.validFromTimestamp) / 1000;
    }

    private updateTimer(): void {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }

        if (this.cart && this.cart.warehouse) {
            this.cart.remainingTime = this.getRemainingValidityTime();
            const warningAt = this.cart.warehouse.cartValidityWarningTime * 60;
            this.timerSubscription = interval(1000)
                .map(tick => {
                    this.cart.remainingTime -= 1;

                    if (this.cart.remainingTime === 0) {
                        this.showTimeoutModal();
                        this.timerSubscription.unsubscribe();
                    } else if (this.cart.remainingTime === warningAt) {
                        this.showTimeoutWarningModal();
                    }
                })
                .subscribe();
        }
    }

    private showTimeoutWarningModal(): void {
        this.warningModal = this.modalService.open(ModalComponent, {
            size: 'lg',
            container: 'nb-layout',
        });
        this.warningModal.componentInstance.header = this.translateService.instant('cart.timeoutWarningHeader');
        this.warningModal.componentInstance.content = this.translateService.instant('cart.timeoutWarningText', { minutes: Math.round(this.cart.remainingTime / 60) });
        this.warningModal.componentInstance.showButton = false;
    }

    private showTimeoutModal(): void {
        if (this.warningModal) {
            this.warningModal.close();
        }

        const timeoutModal = this.modalService.open(CartTimeoutModalComponent, {
            size: 'lg',
            container: 'nb-layout',
            backdrop: 'static',
        });
        timeoutModal.componentInstance.onClear = () => this.removeAllItems().subscribe(
            cart => this.toasterService.popAsync({
                type: 'success',
                body: this.translateService.instant('cart.removedAllFromCart'),
            }),
            err => this.toasterService.popAsync({
                type: 'error',
                body: err && err.error ? err.error : this.translateService.instant('error.serverError'),
            })
        );
        timeoutModal.componentInstance.onClose = () => this.revalidate().subscribe(
            cart => {
                if (cart.messages.length === 0) {
                    this.toasterService.popAsync({
                        type: 'success',
                        body: this.translateService.instant('cart.sucessfullyRevalidated'),
                    })
                }
            },
            err => this.toasterService.popAsync({
                type: 'error',
                body: err && err.error ? err.error : this.translateService.instant('error.serverError'),
            })
        );
    }

    private revalidate(): Observable<Cart> {
        return this.http.putWithResponse<Cart>(AppSettings.CART_URL + "/revalidate")
            .map(cart => {
                this.processCart(cart);
                return this.cart;
            })
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    private loadContainerCapacity(): void {
        this.http.get<number>(AppSettings.SETTINGS_URL + "/containerCapacity")
            .catch((error: HttpErrorResponse) => throwError(error.error))
            .subscribe(
                capacity => {
                    this.containerCapacity = capacity;
                    if (this.cart) {
                        if (this.cart.warehouse && this.cart.warehouse.container) {
                            this.updateContainer();
                            this.cartSubject$.next(this.cart);
                        }
                    }
                },
                err => this.toasterService.popAsync({
                    type: 'error',
                    body: err && err.error ? err.error : this.translateService.instant('error.serverError'),
                }),
            );
    }

    private updateContainer(): void {
        const cartVolume = this.getCartTotalVolume();

        this.totalContainerCapacity = this.containerCapacity;
        let almostFullThreshold = this.containerCapacity - 1;
        let containersCount = 1;

        while (this.totalContainerCapacity < cartVolume) {
            this.totalContainerCapacity *= 2;
            almostFullThreshold *= 2;
            containersCount++;
        }

        this.almostFullContainer = cartVolume >= almostFullThreshold;

        // Check if containers count changed
        if (this.cart && this.cart.items.length > 0 && this.containersCount && this.containersCount !== containersCount) {
            const messageKey = this.containersCount < containersCount ? 'cart.nextContainer' : 'cart.lessContainer';
            this.toasterService.popAsync({
                type: 'info',
                body: this.translateService.instant(messageKey),
            });
        }
        this.containersCount = containersCount;
    }

    loadCart(): Observable<Cart> {
        return this.http.get<Cart>(AppSettings.CART_URL)
            .map(cart => {
                this.processCart(cart);
                return this.cart;
            })
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    addItem(product: Product, amount?: number): Observable<Cart> {
        if (amount === 0) {
            return;
        }
        if (!amount) {
            amount = 1;
        }

        const cartItem: OrderItem = {
            product: product,
            amount: amount,
        };

        return this.addAllItems([cartItem]);
    }

    addAllItems(items: OrderItem[]): Observable<Cart> {
        let data = JSON.stringify(items);

        return this.http.postWithResponse<Cart>(AppSettings.CART_URL, data)
            .map(cart => this.processCart(cart))
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    changeAmount(product: Product, amount: number, single: boolean): Observable<Cart> {
        let data = {
            amount: amount
        };
        let url = AppSettings.CART_URL + "/" + product.id;
        if (single) {
            url = AppSettings.CART_URL + "/single/" + product.id;
        }

        return this.http.put<Cart>(url, data)
            .map(cart => {
                if (this.cart.messages.length === 0 && product.selectedStock.availableStock < amount && this.cart.warehouse.worksWithExpectedStock) {
                    const fromExpected: number = amount - product.selectedStock.availableStock;
                    const expectedDate: string = this.dateUtils.transform(product.selectedStock.expectedDate, this.dateUtils.resolveDateFormat());
                    this.showNotAvailableNowWarningModal(product, fromExpected, expectedDate);
                }
                if (single) {
                    return this.processCartSingle(cart);
                } else {
                    return this.processCart(cart);
                }
            })
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    private showNotAvailableNowWarningModal(product: Product, amountFromExpected: number, expectedDate: string): void {
        this.warningModal = this.modalService.open(ModalComponent, {
            size: 'lg',
            container: 'nb-layout',
        });
        this.warningModal.componentInstance.header = this.translateService.instant('cart.notAvailableNowHeader');
        const textKey = amountFromExpected > 1 ? 'cart.notAvailableNowText' : 'cart.notAvailableNowTextSingular';
        this.warningModal.componentInstance.content = this.translateService.instant(textKey, { product: product.title, amount: amountFromExpected, date: expectedDate });
        this.warningModal.componentInstance.showButton = false;
    }

    removeItem(itemToRemove: OrderItem): Observable<any> {
        return this.http.delete(AppSettings.CART_URL + "/" + itemToRemove.product.id)
            .map(this.processCart.bind(this))
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    removeAllItems() {
        return this.http.delete(AppSettings.CART_URL)
            .map(this.processCart.bind(this))
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getAmount(product: Product): number {
        const item = this.cart ? this.cart.items.find(item => (item.product && item.product.id === product.id)) : null;
        return item ? item.amount : 0;
    }

    private warehouseChanged(warehouse: Warehouse) {
        let data = JSON.stringify(warehouse);

        return this.http.put(AppSettings.CART_URL + "/warehouse", data)
            .map(this.processCart.bind(this))
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    private customerChanged(customer: Customer) {
        let data = JSON.stringify(customer);

        return this.http.put(AppSettings.CART_URL + "/customer", data)
            .map(this.processCart.bind(this))
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    private processCart(cart: Cart): Cart {
        this.cart = cart;

        if (!this.cart) {
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
            this.cartSubject$.next(this.cart);
            return null;
        }

        this.updateTimer();

        if (this.cart.warehouse) {
            if (!this.warehouseContext.warehouse || this.cart.warehouse.id !== this.warehouseContext.warehouse.id) {
                this.warehouseContext.changeWarehouse(this.cart.warehouse);
            }
            if (this.cart.warehouse.container) {
                this.updateContainer();
            }
        }

        this.cartSubject$.next(this.cart);

        // Show timeout modal (only once)
        if (this.cart.active) {
            this.timeoutModalShown = false;
        } else if (!this.timeoutModalShown) {
            this.timeoutModalShown = true;
            this.showTimeoutModal();
        }

        if (this.cart.messages.length > 0) {
            const messagesModal = this.modalService.open(CartMessagesModalComponent, {
                size: 'lg',
                container: 'nb-layout',
            });
            messagesModal.componentInstance.messages = this.cart.messages;
        }

        return this.cart;
    }

    private processCartSingle(cart: Cart): Cart {
        let item: OrderItem = null;
        if (cart.items.length > 0) {
            item = cart.items[0];
        }

        let found = false;
        for (let i = 0; i < this.cart.items.length; i++) {
            if ((item !== null) && (item.product.id === this.cart.items[i].product.id)) {
                this.cart.items[i] = item;
                found = true;
                break;
            }
        }

        if ((!found) && (item !== null)) {
            this.cart.items.push(item);
        }

        // this.cart = cart;
        if (!this.cart) {
            if (this.timerSubscription) {
                this.timerSubscription.unsubscribe();
            }
            this.cartSubject$.next(this.cart);
            return null;
        }

        this.updateTimer();

        if (this.cart.warehouse) {
            if (!this.warehouseContext.warehouse || this.cart.warehouse.id !== this.warehouseContext.warehouse.id) {
                this.warehouseContext.changeWarehouse(this.cart.warehouse);
            }
            if (this.cart.warehouse.container) {
                this.updateContainer();
            }
        }

        this.cartSubject$.next(this.cart);

        // Show timeout modal (only once)
        if (this.cart.active) {
            this.timeoutModalShown = false;
        } else if (!this.timeoutModalShown) {
            this.timeoutModalShown = true;
            this.showTimeoutModal();
        }

        if (this.cart.messages.length > 0) {
            const messagesModal = this.modalService.open(CartMessagesModalComponent, {
                size: 'lg',
                container: 'nb-layout',
            });
            messagesModal.componentInstance.messages = this.cart.messages;
        }

        return this.cart;
    }

    submitOrder(): Observable<number> {
        const data = JSON.stringify(this.cart);

        return this.http.post<HttpResponse<any>>(AppSettings.ORDERS_URL, data, null, { observe: 'response' })
            .map((response: HttpResponse<any>) => {
                this.processCart(null);
                const location = response.headers.get('Location');
                const id = location.substring(location.lastIndexOf("/") + 1);
                return parseInt(id);
            })
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

}
