import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Order} from './order';
import {PagedData} from './paged-data';
import {Filter} from './filter';

export interface OrderFilter extends Filter {
    order?: number;
}

export interface OrderStatus {
    value: string;
    label: string;
}

@Injectable()
export class OrderService {

    private orderStatuses: OrderStatus[] = [
        {
            value: 'NEW',
            label: 'NEW'
        }, {
            value: 'IN_PROCESS',
            label: 'IN PROCESS'
        }, {
            value: 'CANCELLED',
            label: 'CANCELLED'
        }, {
            value: 'FINISHED',
            label: 'FINISHED'
        }];

    constructor(private http: AuthHttpClient) {
    }

    getOrders(params?: OrderFilter): Observable<PagedData<Order>> {
        return this.http.get<PagedData<Order>>(AppSettings.ORDERS_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getOrderDetail(id: number): Observable<Order> {
        return this.http.get<Order>(AppSettings.ORDERS_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    accept(id: number): Observable<boolean> {
        return this.http.put(AppSettings.ORDERS_URL + "/" + id + "/accept", {})
            .map(res => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    cancel(id: number, updateStock: boolean): Observable<boolean> {
        let queryParams = "";
        if (updateStock) {
            queryParams += "?updateStock=true";
        }

        return this.http.put(AppSettings.ORDERS_URL + "/" + id + "/cancel" + queryParams, {})
            .map(res => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    finish(id: number): Observable<boolean> {
        return this.http.put(AppSettings.ORDERS_URL + "/" + id + "/finish", {})
            .map(res => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getAllStatuses(): Observable<OrderStatus[]> {
        return Observable.of(this.orderStatuses);
    }
}
