import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import 'rxjs/Rx';
import {Router} from '@angular/router';

import {AppSettings} from '../app.settings';
import {CurrentUser} from './current-user';
import {CurrentUserContext} from './current-user-context';

export interface LoginResponse {
    user: CurrentUser;
    accessToken: string;
    refreshToken: string;
    resetPasswordToken: string;
    mustChangePassword: boolean;
}

export interface RefreshTokenResponse {
    accessToken: string;
    refreshToken: string;
}

@Injectable()
export class AuthenticationService {

    public redirectUrl: string;

    constructor(private http: HttpClient,
        private currentUserContext: CurrentUserContext,
        private router: Router) {
    }

    isLoggedIn() {
        return this.currentUserContext.isLoggedIn();
    }

    login(email: string, password: string): Observable<boolean> {
        const options = {headers: new HttpHeaders({'Content-Type': 'application/json'})};
        const data = JSON.stringify({email: email, password: password});

        return this.http.post<LoginResponse>(AppSettings.LOGIN_URL, data, options)
            .map(response => {
                const accessToken = response && response.accessToken;
                if (accessToken) {
                    if (response.mustChangePassword) {
                        // password must be changed so redirect to the reset password page
                        this.router.navigate(['/auth/reset-password', response.resetPasswordToken]);
                        return false;
                    }

                    this.saveLoggedUser(response);

                    // return true to indicate successful login
                    return true;
                }

                return false;
            })
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    saveLoggedUser(response: LoginResponse): void {
        this.currentUserContext.saveCurrentUser(response.user);
        this.saveAccessToken(response.accessToken);
        this.saveRefreshToken(response.refreshToken);
    }

    refreshToken(): Observable<string> {
        const options = {
            headers: new HttpHeaders({
                Authorization: "Bearer " + this.getRefreshToken(),
                Pragma: 'no-cache',
                Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
            })
        };

        return this.http.get<RefreshTokenResponse>(AppSettings.REFRESH_TOKEN_URL, options)
            .map(response => {
                const accessToken = response && response.accessToken;
                this.saveAccessToken(accessToken);

                const refreshToken = response && response.refreshToken;
                this.saveRefreshToken(refreshToken);

                return accessToken;
            });
    }

    logout(withoutRedirect?: boolean): void {
        localStorage.clear();
        if (!withoutRedirect) {
            window.location.href = '/auth/login';
        }
    }

    resetPassword(token: string, newPassword: string): Observable<LoginResponse> {
        const options = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

        return this.http.post<LoginResponse>(AppSettings.RESET_PASSWORD_URI + "?resetToken=" + token, {newPassword: newPassword}, options)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    notifyForgottenPassword(email: string): Observable<boolean> {
        const options = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

        return this.http.post(AppSettings.FORGOTTEN_PASSWORD_URI + "?email=" + email, {}, options)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    get token(): string {
        return localStorage.getItem("accessToken");
    }

    private saveAccessToken(token: string): void {
        localStorage.setItem("accessToken", token);
    }

    private saveRefreshToken(token: string): void {
        localStorage.setItem("refreshToken", token);
    }

    private getRefreshToken(): string {
        return localStorage.getItem("refreshToken");
    }
}
