import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {FileCategory} from './file-category';

@Injectable()
export class FileCategoryService {

    constructor(private http: AuthHttpClient) {
    }

    getFileCategories(active?: boolean): Observable<FileCategory[]> {
        let params = <any> {};
        if (active !== undefined) {
            params.active = active;
        }
        return this.http.get<FileCategory[]>(AppSettings.FILE_CATEGORIES_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getFileCategoryDetail(id: number): Observable<FileCategory> {
        return this.http.get<FileCategory>(AppSettings.FILE_CATEGORIES_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createFileCategory(fileCategory: FileCategory): Observable<any> {
        var data = JSON.stringify(fileCategory);

        return this.http.post(AppSettings.FILE_CATEGORIES_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editFileCategory(fileCategory: FileCategory): Observable<any> {
        var data = JSON.stringify(fileCategory);

        return this.http.put(AppSettings.FILE_CATEGORIES_URL + "/" + fileCategory.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteFileCategory(fileCategory: FileCategory): Observable<any> {
        return this.http.delete(AppSettings.FILE_CATEGORIES_URL + "/" + fileCategory.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}


