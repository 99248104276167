import {AbstractControl} from '@angular/forms';

export class EmailValidator {

    public static validate(c: AbstractControl) {
        return EmailValidator.validateValue(c.value.trim()) ? null : {
            validateEmail: {
                valid: false
            }
        };
    }

    public static validateValue(value: string) {
        let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
        return EMAIL_REGEXP.test(value);
    }
}
