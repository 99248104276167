<nb-auth-block>
    <h2 class="title">{{ 'forgotten.title' | translate }}</h2>
    <small class="form-text sub-title">{{ 'forgotten.perex' | translate }}</small>

    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">

        <nb-alert *ngIf="error && !submitted" outline="danger" class="text-danger">
            {{ error }}
        </nb-alert>

        <nb-alert *ngIf="success && !submitted" outline="success" class="text-success">
            {{ 'forgotten.emailSent' | translate }}
        </nb-alert>

        <div class="form-group">
            <label for="input-email" class="sr-only">{{ 'login.email' | translate }}</label>
            <input nbInput
                   [formControl]="form.controls['email']" 
                   #email="ngForm"
                   id="input-email"
                   name="email"
                   [placeholder]="'login.email' | translate"
                   autofocus
                   fullWidth
                   pattern=".+@.+\..+"
                   [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''" />
            <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
                {{ 'login.emailRequired' | translate }}
            </small>
            <small class="form-text error"
                *ngIf="email.invalid && email.touched && email.errors?.pattern">
                {{ 'login.emailInvalid' | translate }}
            </small>
        </div>

        <button nbButton
                status="primary"
                fullWidth
                [disabled]="submitted || !form.valid"
                [class.btn-pulse]="submitted">
                {{ 'forgotten.resetPassword' | translate }}
        </button>
    </form>

    <div class="links col-sm-12">
        <small class="form-text">
            <a routerLink="../login"><strong><i class="fas fa-chevron-left"></i> {{ 'forgotten.backToLogin' | translate }}</strong></a>
        </small>
    </div>
</nb-auth-block>
