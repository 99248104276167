import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Country} from './country';

@Injectable()
export class CountryService {

    constructor(private http: AuthHttpClient) {
    }

    getCountries(onlyActive?: boolean): Observable<Country[]> {
        const params: any = {};
        if (onlyActive !== undefined) {
            params.active = onlyActive;
        }
        return this.http.get<Country[]>(AppSettings.COUNTRIES_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getCountryDetail(id: number): Observable<Country> {
        return this.http.get<Country>(AppSettings.COUNTRIES_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createCountry(country: Country): Observable<any> {
        var data = JSON.stringify(country);

        return this.http.post(AppSettings.COUNTRIES_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editCountry(country: Country): Observable<any> {
        var data = JSON.stringify(country);

        return this.http.put(AppSettings.COUNTRIES_URL + "/" + country.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    invalidateCountry(country: Country): Observable<any> {
        return this.http.delete(AppSettings.COUNTRIES_URL + "/" + country.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
