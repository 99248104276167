
    <div class="modal-header">
        <h3>{{ 'reloadModal.title' | translate }}</h3>
        <button class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>{{ 'reloadModal.text' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-md btn-primary" (click)="reload()"><i class="fa fa-refresh" aria-hidden="true"></i> {{ 'reloadModal.reloadBtn' | translate }}</button>
        <button class="btn btn-md btn-secondary" (click)="closeModal()">{{ 'common.back' | translate }}</button>
    </div>
