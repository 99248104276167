import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ToasterService} from 'angular2-toaster';
import {NbMenuService, NbSidebarService, NbMenuItem} from '@nebular/theme';
import {filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs/Subscription';

import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {LayoutService} from '../../../@core/data/layout.service';
import {CurrentUserContext} from '../../../auth/current-user-context';
import {WarehouseContext} from '../../../@core/data/warehouse-context';
import {Warehouse} from '../../../@core/data/warehouse';
import {CustomerContext} from '../../../@core/data/customer-context';
import {Customer} from '../../../@core/data/customer';
import {CurrentUser} from '../../../auth/current-user';
import {Cart} from '../../../@core/data/order';
import {CartService} from '../../../@core/data/cart.service';
import {Authorizator} from '../../../auth/authorizator';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    @Input() position = 'normal';

    user: CurrentUser;
    private userSubscription: Subscription;

    private cartSubscription: Subscription;
    cart: Cart;
    cartCountLabel: string = "0 pcs";
    cartVolume: string = "0";
    containerCapacity: number;
    totalContainerCapacity: number;
    containersCount: number;
    almostFullContainer: boolean = false;

    private customerSubscription: Subscription;
    customer: Customer;
    private assignedCustomersSubscription: Subscription;
    showCustomerSelect: boolean = false;

    private warehouseSubscription: Subscription;
    warehouse: Warehouse;
    private allowedWarehousesSubscription: Subscription;
    showWarehouseSelect: boolean = false;

    userMenu: NbMenuItem[] = [];

    constructor(private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private router: Router,
        public authorizator: Authorizator,
        private cartService: CartService,
        private analyticsService: AnalyticsService,
        private translateService: TranslateService,
        private toasterService: ToasterService,
        private currentUserContext: CurrentUserContext,
        private warehouseContext: WarehouseContext,
        private customerContext: CustomerContext,
        private layoutService: LayoutService) {
    }

    ngOnInit() {
        this.userMenu = [
            {title: this.translateService.instant('menu.profile'), data: {path: '/pages/profile'}},
            {title: this.translateService.instant('menu.logout'), link: '/auth/logout'}
        ];

        this.userSubscription = this.currentUserContext.currentUserSubject$.subscribe(user => this.user = user);

        this.assignedCustomersSubscription = this.customerContext.assignedCustomerSubject$.subscribe(customers => this.showCustomerSelect = customers.length > 1);
        this.customerSubscription = this.customerContext.customerSubject$.subscribe(customer => this.customer = customer);

        this.allowedWarehousesSubscription = this.warehouseContext.assignedWarehouseSubject$.subscribe(warehouses => this.showWarehouseSelect = warehouses.length > 1);
        this.warehouseSubscription = this.warehouseContext.warehouseSubject$.subscribe(warehouse => this.warehouse = warehouse);

        this.cartSubscription = this.cartService.cartSubject$.subscribe(
            cart => {
                this.cart = cart;
                this.updateCountLabel();
                this.updateVolumeLabel();
            },
            err => (this.cartCountLabel = "0 pcs")
        );

        this.menuService.onItemClick()
            .pipe(
                filter(({tag}) => tag === 'user-menu'),
                map(itemMeta => itemMeta.item)
            )
            .subscribe((item: any) => {
                if (item.data && item.data.path) {
                    this.router.navigateByUrl(item.data.path);
                }
            });
    }

    selectWarehouse() {
        this.warehouseContext.changeWarehouse(this.warehouse);
    }

    selectCustomer() {
        this.customerContext.changeCustomer(this.customer);
    }

    private updateCountLabel(): void {
        const count = this.cartService.getCartItemsCount();
        const pcsLabel = count === 1 ? this.translateService.instant('common.pc') : this.translateService.instant('common.pcs');
        this.cartCountLabel = count + " " + pcsLabel;
    }

    private updateVolumeLabel(): void {
        this.cartVolume = this.cartService.getCartTotalVolume().toFixed(2);
        this.totalContainerCapacity = this.cartService.totalContainerCapacity;
        this.almostFullContainer = this.cartService.almostFullContainer;
        this.containersCount = this.cartService.containersCount;
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
        if (this.cartSubscription) {
            this.cartSubscription.unsubscribe();
        }
        if (this.warehouseSubscription) {
            this.warehouseSubscription.unsubscribe();
        }
        if (this.allowedWarehousesSubscription) {
            this.allowedWarehousesSubscription.unsubscribe();
        }
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
        if (this.assignedCustomersSubscription) {
            this.assignedCustomersSubscription.unsubscribe();
        }
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();

        return false;
    }

    goToHome() {
        this.router.navigateByUrl("/pages/home");
    }

    startSearch() {
        this.analyticsService.trackEvent('startSearch');
    }
}
