import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {HttpErrorResponse} from '@angular/common/http';
import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';

@Injectable()
export class PingService {

    constructor(private http: AuthHttpClient) {}

    ping(): Observable<any> {
        return this.http.post<void>(AppSettings.PING_URL, {})
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}