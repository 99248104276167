import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ngx-switcher',
    styleUrls: ['./switcher.component.scss'],
    template: `
    <label class="switcher">
      <span class="left">{{ onText }}</span>
      <div class="switch">
        <input type="checkbox" [checked]=state (change)="onChange(!$event.target.checked)">
        <span class="slider"></span>
      </div>
      <span class="right">{{ offText }}</span>
    </label>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitcherComponent),
            multi: true
        }
    ],
})
export class SwitcherComponent implements ControlValueAccessor {

    @Input() onText: string = "Left";
    @Input() offText: string = "Right";
    @Input() callback: Function;
    public state: boolean;

    public constructor() {}

    public onChange(value: any): void {}
    public onTouch(value: any): void {}
    public writeValue(state: boolean): void {
        this.state = !state;
    }
    public registerOnChange(fn: any): void {this.onChange = fn;}
    public registerOnTouched(fn: any): void {this.onTouch = fn;}
}
