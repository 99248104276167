import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthHttpClient } from '../../auth/http-client';
import { AppSettings } from '../../app.settings';
import { PagedData } from './paged-data';
import { AppLog, LogType, LogArea } from './app-log';


@Injectable()
export class AppLogService {

    constructor(private http: AuthHttpClient) {
    }

    getLog(pageNumber: number, pageSize: number, logArea: string, logType: string, message: string, reference: string, referenceID: string): Observable<PagedData<AppLog>> {
        const params: any = {};
        params.pageNumber = pageNumber;
        params.pageSize = pageSize;
        params.logArea = logArea;
        params.logType = logType;
        params.message = message;
        params.reference = reference;
        params.referenceID = referenceID;
        return this.http.get<PagedData<AppLog>>(AppSettings.APP_LOG_URL + '/limitlog', params)
            .catch((error: any) => {
                return Observable.throw(error.error);
            });
    }

    getAreas(): Observable<LogArea[]> {
        return this.http.get<LogArea[]>(AppSettings.APP_LOG_URL + '/logAreas')
            .catch((error: any) => {
                return Observable.throw(error.error);
            });
    }

    getTypes(): Observable<LogType[]> {
        return this.http.get<LogType[]>(AppSettings.APP_LOG_URL + '/logTypes')
            .catch((error: any) => {
                return Observable.throw(error.error);
            });
    }
}
