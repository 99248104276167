import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Warehouse} from './warehouse';

@Injectable()
export class WarehouseService {

    constructor(private http: AuthHttpClient) {
    }

    getWarehouses(active?: boolean, onlyCustomer?: boolean): Observable<Warehouse[]> {
        let params = <any> {};
        if (active !== undefined) {
            params.active = active;
        }
        if (onlyCustomer !== undefined) {
            params.onlyCustomer = onlyCustomer;
        }
        return this.http.get<Warehouse[]>(AppSettings.WAREHOUSES_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getWarehouseDetail(id: number): Observable<Warehouse> {
        return this.http.get<Warehouse>(AppSettings.WAREHOUSES_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createWarehouse(warehouse: Warehouse): Observable<any> {
        var data = JSON.stringify(warehouse);

        return this.http.post(AppSettings.WAREHOUSES_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editWarehouse(warehouse: Warehouse): Observable<any> {
        var data = JSON.stringify(warehouse);

        return this.http.put(AppSettings.WAREHOUSES_URL + "/" + warehouse.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteWarehouse(warehouse: Warehouse): Observable<any> {
        return this.http.delete(AppSettings.WAREHOUSES_URL + "/" + warehouse.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}


