import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthHttpClient } from '../../auth/http-client';
import { AppSettings } from '../../app.settings';
import { Product } from './product';
import { Warehouse } from './warehouse';
import { PagedData } from './paged-data';
import { Filter } from './filter';

export interface ProductFilter extends Filter {
    category?: number;
    range?: number;
    customer?: number;
    warehouse?: number;
    available?: boolean;
}

@Injectable()
export class ProductService {

    constructor(private http: AuthHttpClient) {
    }

    getProducts(params: ProductFilter): Observable<PagedData<Product>> {
        return this.http.get<PagedData<Product>>(AppSettings.PRODUCTS_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getProductDetail(id: number): Observable<Product> {
        return this.http.get<Product>(AppSettings.PRODUCTS_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createProduct(product: Product): Observable<any> {
        var data = JSON.stringify(product);

        return this.http.post(AppSettings.PRODUCTS_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editProduct(product: Product): Observable<any> {
        var data = JSON.stringify(product);

        return this.http.put(AppSettings.PRODUCTS_URL + "/" + product.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteProduct(product: Product): Observable<any> {
        return this.http.delete(AppSettings.PRODUCTS_URL + "/" + product.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    downloadFiles(product: Product): Observable<any> {
        return this.http.getBlob(AppSettings.PRODUCTS_URL + "/" + product.id + "/files", "application/zip")
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    removeGFromCodes(): Observable<any> {
        return this.http.post(AppSettings.PRODUCTS_URL + "/removeg", null)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    exportProducts(warehouse: Warehouse): Observable<Blob> {
        return this.http.getBlob(AppSettings.PRODUCTS_URL + "/xlsx?warehouse=" + warehouse.id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
