import {Component, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

import {Customer} from '../../../@core/data/customer'

@Component({
    selector: 'customer-select-modal',
    styleUrls: ['./customer-select-modal.component.scss'],
    templateUrl: './customer-select-modal.component.html',
})
export class CustomerSelectModalComponent {

    @Input()
    customers: Customer[] = [];

    @Input()
    onSelect: Function;

    constructor(private activeModal: NgbActiveModal,
        private translateService: TranslateService) {
    }

    select(customer: Customer) {
        if (this.onSelect) {
            this.onSelect(customer);
        }
        this.activeModal.close();
    }
}