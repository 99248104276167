import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {LocaleResolver} from '../@core/utils/locale.resolver';

@Injectable()
export class AuthHttpClient {

    constructor(private http: HttpClient,
        private localeResolver: LocaleResolver) {}

    prepareHeaders(contentType?: string) {
        if (contentType == undefined) {
            contentType = 'application/json';
        } else if (contentType === "multipart/form-data") {
            // Ignore multipart/form-data header
            contentType = null;
        }

        let headers = new HttpHeaders()
            .append('Authorization', 'Bearer ' + localStorage.getItem("accessToken"))
            .append('Accept-Language', this.localeResolver.language)
            .append('Pragma', 'no-cache')
            .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

        if (contentType) {
            return headers.append('Content-Type', contentType);
        }
        return headers;
    }

    getBlob(url: string, accept?: string) {
        let headers = this.prepareHeaders()

        if (accept) {
            headers = headers
                .append('Accept', accept);
        }

        let params = new HttpParams();

        return this.http.get(url, {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'blob'
        });
    };

    getFileBinary(url: string) {
        let headers = this.prepareHeaders()
            .append('Accept', "application/octet-stream");

        let params = new HttpParams();

        return this.http.get(url, {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'blob'
        });
    };

    get<T>(url, data?, accept?) {
        if (accept == undefined) {
            accept = "application/json";
        }

        let headers = this.prepareHeaders()
            .append('Accept', accept);

        let params = new HttpParams();
        if (data !== undefined) {
            for (const param of data) {
                params = params.set(param, data[param]);
            }
            for (const param in data) {
                params = params.set(param, data[param]);
            }
        }

        return this.http.get<T>(url, {
            headers: headers,
            params: params
        });
    };

    postExcelFile(url, data) {
        const headers = this.prepareHeaders('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

        let params = new HttpParams();

        return this.http.post(url, data, {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'blob',
        });
    }

    post<T>(url: string, data: any, urlParams?: any, options?: any) {
        const headers = this.prepareHeaders(options ? options.contentType : undefined);

        let params = new HttpParams();
        if (urlParams) {
            for (const param in urlParams) {
                params = params.set(param, urlParams[param]);
            }
        }

        return this.http.post<T>(url, data, {
            headers: headers,
            params: params,
            responseType: options && options.responseType ? options.responseType : 'json',
            observe: options && options.observe ? options.observe : 'body',
        });
    }

    postWithResponse<T>(url: string, data: any, urlParams?: any) {
        const headers = this.prepareHeaders();

        let params = new HttpParams();
        if (urlParams) {
            for (const param in urlParams) {
                params = params.set(param, urlParams[param]);
            }
        }

        return this.http.post<T>(url, data, {
            headers: headers,
            params: params,
            responseType: 'json',
            observe: 'body',
        });
    }

    put<T>(url, data) {
        const headers = this.prepareHeaders();
        return this.http.put<T>(url, data, {
            headers: headers
        });
    }

    putWithResponse<T>(url: string, data?: any, urlParams?: any) {
        const headers = this.prepareHeaders();

        let params = new HttpParams();
        if (urlParams) {
            for (const param in urlParams) {
                params = params.set(param, urlParams[param]);
            }
        }

        return this.http.put<T>(url, data, {
            headers: headers,
            params: params,
            responseType: 'json',
            observe: 'body',
        });
    }

    delete(url) {
        const headers = this.prepareHeaders();
        return this.http.delete(url, {
            headers: headers
        });
    }
}
