import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {TranslateModule} from '@ngx-translate/core';

import {
    NbActionsModule,
    NbCardModule,
    NbLayoutModule,
    NbListModule,
    NbMenuModule,
    NbRouteTabsetModule,
    NbSearchModule,
    NbSidebarModule,
    NbTabsetModule,
    NbThemeModule,
    NbUserModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbProgressBarModule,
    NbDatepickerModule,
    NbInputModule,
    NbTooltipModule,
} from '@nebular/theme';

import {NbSecurityModule} from '@nebular/security';

import {
    BaCheckbox,
    BaMultiCheckbox,
    FooterComponent,
    HeaderComponent,
    ModalComponent,
    SearchInputComponent,
    SwitcherComponent,
    TinyMCEComponent,
    WarehouseSelectComponent,
    CartMessagesModalComponent,
    CartTimeoutModalComponent,
    WarehouseSelectModalComponent,
    CustomerSelectModalComponent,
    CustomerSelectComponent,
} from './components';
import {
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    ClockPipe,
} from './pipes';
import {
    EmailValidator,
    EqualPasswordsValidator,
    MinSelectedValidator,
} from './validators';
import {
    SampleLayoutComponent,
} from './layouts';
import {CORPORATE_THEME} from './styles/theme.corporate';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule];

const NB_MODULES = [
    NbCardModule,
    NbLayoutModule,
    NbListModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    NbSearchModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NgbModule,
    NbSecurityModule, // *nbIsGranted directive,
    NbProgressBarModule,
    NbDatepickerModule,
    NbInputModule,
    NbTooltipModule,
];

const CUSTOM_MODULES = [
    TranslateModule,
    NgSelectModule,
];

const COMPONENTS = [
    BaCheckbox,
    BaMultiCheckbox,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    SampleLayoutComponent,
    SearchInputComponent,
    SwitcherComponent,
    TinyMCEComponent,
    WarehouseSelectComponent,
    CartMessagesModalComponent,
    CartTimeoutModalComponent,
    WarehouseSelectModalComponent,
    CustomerSelectComponent,
    CustomerSelectModalComponent,
];

const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
    ClockPipe,
];

const VALIDATORS = [
    EmailValidator,
    EqualPasswordsValidator,
    MinSelectedValidator,
];

const ENTRY_COMPONENTS = [
    ModalComponent,
    CartMessagesModalComponent,
    CartTimeoutModalComponent,
    WarehouseSelectModalComponent,
    CustomerSelectModalComponent,
];

const NB_THEME_PROVIDERS = [
    ...NbThemeModule.forRoot(
        {
            name: 'corporate',
        },
        [CORPORATE_THEME],
    ).providers,
    ...NbSidebarModule.forRoot().providers,
    ...NbMenuModule.forRoot().providers,
    ...NbDatepickerModule.forRoot().providers,
    ...NbDatepickerModule.forRoot().providers,
];

@NgModule({
    imports: [...BASE_MODULES, ...NB_MODULES, ...CUSTOM_MODULES],
    exports: [...BASE_MODULES, ...NB_MODULES, ...COMPONENTS, ...PIPES],
    declarations: [...COMPONENTS, ...PIPES],
    entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders {
        return <ModuleWithProviders> {
            ngModule: ThemeModule,
            providers: [...NB_THEME_PROVIDERS, VALIDATORS],
        };
    }
}
