import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'cart-timeout-modal',
    styleUrls: ['./cart-timeout-modal.component.scss'],
    templateUrl: './cart-timeout-modal.component.html',
})
export class CartTimeoutModalComponent {

    @Input()
    onClear: Function;

    @Input()
    onClose: Function;

    constructor(private activeModal: NgbActiveModal,
        private router: Router,
        private translateService: TranslateService) {
    }

    closeModal() {
        if (this.onClose) {
            this.onClose();
        }
        this.activeModal.close();
    }

    clearCart() {
        if (this.onClear) {
            this.onClear();
        }
        this.activeModal.close();
    }
}