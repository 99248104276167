import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {User} from './user';
import {PagedData} from './paged-data';
import {Filter} from './filter';

export interface UserFilter extends Filter {
    customer?: number;
}

@Injectable()
export class UserService {

    constructor(private http: AuthHttpClient) {
    }

    getUsers(params?: UserFilter): Observable<PagedData<User>> {
        return this.http.get<PagedData<User>>(AppSettings.USERS_URL, params)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getUserDetail(id: number): Observable<User> {
        return this.http.get<User>(AppSettings.USERS_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    createUser(user: User): Observable<any> {
        var data = JSON.stringify(user);

        return this.http.post(AppSettings.USERS_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editUser(user: User): Observable<any> {
        var data = JSON.stringify(user);

        return this.http.put(AppSettings.USERS_URL + "/" + user.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    deleteUser(user: User): Observable<any> {
        return this.http.delete(AppSettings.USERS_URL + "/" + user.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    exportUsers(): Observable<Blob> {
        return this.http.getBlob(AppSettings.USERS_URL + "/xlsx")
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
