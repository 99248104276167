import {Component, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ba-checkbox[ngModel]',
    styles: ['./baCheckbox.component.scss'],
    templateUrl: './baCheckbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaCheckbox),
            multi: true
        }
    ],
})
export class BaCheckbox implements ControlValueAccessor {

    @Input() disabled: boolean;
    @Input() label: string;
    @Input() value: string;
    @Input() baCheckboxClass: string;

    public state: boolean;

    public constructor() {}

    public onChange(value: any): void {}
    public onTouch(value: any): void {}
    public writeValue(state: any): void {
        this.state = state;
    }

    public registerOnChange(fn: any): void {this.onChange = fn;}
    public registerOnTouched(fn: any): void {this.onTouch = fn;}
}
