import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <div class="row">
        <div class="col-12 created-by">Copyright &copy; 2019 | Global Home Group</div>
    </div>
  `,
})
export class FooterComponent {
}
