import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import {
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NbCheckboxModule,
    NbInputModule,
    NbLayoutModule,
} from '@nebular/theme';

import {ThemeModule} from '../@theme/theme.module';
import {NbAuthBlockComponent} from './auth-block/auth-block.component';
import {AuthGuard} from './auth.guard';
import {AuthHttpClient} from './http-client';
import {Authorizator} from './authorizator';
import {AuthRoutingModule, routedComponents} from './auth-routing.module';
import {CurrentUserContext} from './current-user-context';

@NgModule({
    imports: [
        ThemeModule,
        TranslateModule,
        AuthRoutingModule,
        NgbAlertModule,
        NbLayoutModule,
        NbCardModule,
        NbCheckboxModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
    ],
    declarations: [
        routedComponents,
        NbAuthBlockComponent,
    ],
    providers: [
        AuthGuard,
        AuthHttpClient,
        Authorizator,
        CurrentUserContext,
    ]
})
export class AuthModule {
}
