<div class="modal-header">
    <span>{{ header }}</span>
    <button class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [innerHTML]="content"></div>
<div class="modal-footer">
    <button *ngIf="showButton" class="btn btn-{{ buttonType }}" (click)="buttonClicked()" [innerHTML]="buttonText"></button>
    <button class="btn btn-secondary " (click)="closeModal()"><i class="ion-ios-arrow-back "></i> {{ 'common.back' | translate }}</button>
</div>
