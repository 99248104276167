<nb-auth-block>
    <h2 class="title">{{ 'login.signIn' | translate }}</h2>
    <small class="form-text sub-title" [innerHtml]="'login.perex' | translate"></small>

    <form [formGroup]="form" (ngSubmit)="login(form.value)" autocomplete="nope">

        <nb-alert *ngIf="error.length !submitted" outline="danger" class="text-danger">{{ error }}</nb-alert>

        <div class="form-group">
            <label for="input-email" class="sr-only">{{ 'login.email' | translate }}</label>
            <input nbInput
                   [formControl]="form.controls['email']" 
                   #email="ngForm"
                   name="email"
                   id="input-email"
                   pattern=".+@.+\..+"
                   [placeholder]="'login.email' | translate"
                   autofocus />
            <small class="form-text error" *ngIf="email.invalid && email.touched && email.errors?.required">
                {{ 'login.emailRequired' | translate }}
            </small>
            <small class="form-text error"
               *ngIf="email.invalid && email.touched && email.errors?.pattern">
               {{ 'login.emailInvalid' | translate }}
            </small>
        </div>

        <div class="form-group">
            <label for="input-password" class="sr-only">{{ 'login.password' | translate }}</label>
            <input nbInput
                   [formControl]="form.controls['password']" 
                   #password="ngForm"
                   name="password"
                   type="password"
                   id="input-password"
                   [placeholder]="'login.password' | translate" />
            <small class="form-text error" *ngIf="password.invalid && password.touched && password.errors?.required">
                {{ 'login.passwordRequired' | translate }}
            </small>
        </div>
        
        <button nbButton
                status="primary"
                fullWidth
                [disabled]="submitted || !form.valid"
                [class.btn-pulse]="submitted">
                <i class="fas fa-sign-in-alt"></i> {{ 'login.signIn' | translate }}
        </button>

        <div class="links col-sm-12">
            <small class="form-text">
                <a routerLink="../forgotten-password"><strong>{{ 'login.forgotPassword' | translate }}</strong></a>
            </small>
        </div>
    </form>
</nb-auth-block>
