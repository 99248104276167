import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Authority} from './authority';
import {AuthorityArea} from './authority-area';

@Injectable()
export class AuthorityService {

    constructor(private http: AuthHttpClient) {
    }

    createAuthorityArea(area: AuthorityArea): Observable<any> {
        var data = JSON.stringify(area);

        return this.http.post(AppSettings.AUTHORITY_AREAS_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editAuthorityArea(area: AuthorityArea): Observable<any> {
        var data = JSON.stringify(area);

        return this.http.put(AppSettings.AUTHORITY_AREAS_URL + "/" + area.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    invalidateAuthorityArea(area: AuthorityArea): Observable<any> {
        return this.http.delete(AppSettings.AUTHORITY_AREAS_URL + "/" + area.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getAuthorityAreas(): Observable<AuthorityArea[]> {
        return this.http.get<AuthorityArea[]>(AppSettings.AUTHORITY_AREAS_URL)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getAuthorityAreaDetail(id: number): Observable<AuthorityArea> {
        return this.http.get<AuthorityArea>(AppSettings.AUTHORITY_AREAS_URL + "/" + id)
            .catch((error: any) => {
                return Observable.throw(error.error);
            });
    }

    createAuthority(authority: Authority): Observable<any> {
        var data = JSON.stringify(authority);

        return this.http.post(AppSettings.AUTHORITIES_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    editAuthority(authority: Authority): Observable<any> {
        var data = JSON.stringify(authority);

        return this.http.put(AppSettings.AUTHORITIES_URL + "/" + authority.id, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    invalidateAuthority(authority: Authority): Observable<any> {
        return this.http.delete(AppSettings.AUTHORITIES_URL + "/" + authority.id)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getAuthorities(): Observable<Authority[]> {
        return this.http.get<Authority[]>(AppSettings.AUTHORITIES_URL)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    getAuthorityDetail(id: number): Observable<Authority> {
        return this.http.get<Authority>(AppSettings.AUTHORITIES_URL + "/" + id)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }
}
