import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';
import {Settings} from './settings';

@Injectable()
export class SettingsService {

    constructor(private http: AuthHttpClient) {}

    public getSettings(): Observable<Settings> {
        return this.http.get<Settings>(AppSettings.SETTINGS_URL)
            .catch((error: HttpErrorResponse) => (throwError(error.error)));
    }

    public editSettings(settings: Settings): Observable<boolean> {
        var data = JSON.stringify(settings);

        return this.http.put(AppSettings.SETTINGS_URL, data)
            .map(response => true)
            .catch((error: HttpErrorResponse) => (throwError(error.error)));
    }
}
