import {Component} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {EmailValidator} from '../../@theme/validators/email.validator';
import {TranslateService} from '@ngx-translate/core';

import {AuthenticationService} from '../auth.service';

@Component({
    templateUrl: './forgotten-password.component.html'
})
export class ForgottenPasswordComponent {

    form: FormGroup;
    submitted: boolean = false;
    success: boolean = false;
    error: string = '';

    constructor(fb: FormBuilder,
        private translateService: TranslateService,
        private authService: AuthenticationService) {
        this.form = fb.group({
            'email': ['', Validators.compose([Validators.required, EmailValidator.validate])]
        });
    }

    public onSubmit(values: any): void {
        if (this.form.valid) {
            this.submitted = true;
            this.error = '';
            this.authService.notifyForgottenPassword(values.email)
                .subscribe(result => {
                    this.success = true;
                    this.submitted = false;
                }, err => {
                    this.success = false;
                    this.submitted = false;
                    this.error = err && err.error ? this.translateService.instant('error.' + err.error) : this.translateService.instant('error.serverError');
                });
        }
    }
}
