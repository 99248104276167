import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {HttpErrorResponse} from '@angular/common/http';
import {AuthHttpClient} from '../../auth/http-client';
import {AppSettings} from '../../app.settings';

@Injectable()
export class FileService {

    constructor(private http: AuthHttpClient) {}

    getFileBinary(fileId: number): Observable<Blob> {
        return this.http.getFileBinary(AppSettings.FILES_URL + "/binary/" + fileId)
            .catch((error: any) => {
                return Observable.throw(error.error);
            });
    }

    migrate(): Observable<any> {        
        return this.http.post(AppSettings.FILES_URL + "/migrate", null)
            .map(response => true)
            .catch((error: HttpErrorResponse) => throwError(error.error));
    }

    uploadPicture(): void {

    }
}